import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpOptions } from '@xpo-ltl/data-api';
import {
  AdminPersonnel,
  CreatePricingCodeResp,
  CreatePricingCodeRqst,
  DeletePricingCodePath,
  DeleteSalesRfpAcctsRulesetPath,
  GetSalesRfpPath,
  GetSalesRfpQuery,
  GetSalesRfpResp,
  ListPricingAnalystsQuery,
  ListPricingAnalystsResp,
  ListPricingCodesQuery,
  ListPricingCodesResp,
  ListSalesRfpHeadersQuery,
  ListSalesRfpHeadersResp,
  PricingCode,
  PricingWorkbenchApiService,
  SalesRfpHeader,
  UpdatePricingCodePath,
  UpdatePricingCodeResp,
  UpdatePricingCodeRqst,
} from '@xpo-ltl/sdk-pricingworkbench';
import _ from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { DptDiscountCodes } from '../../../dpt-discount/enums/dpt-discount-codes.enum';
import { ConstantsService } from '../constants/constants.service';
import { PricingWorkbenchCodes } from './pricing-codes.data';

export interface CodesQuery {
  category: string[];
  subCategory: string[];
}
@Injectable({
  providedIn: 'root',
})
export class PricingWorkbenchService {
  private pricingCodesCache$: Observable<PricingCode[]>;

  private defaultCategories: string[] = [
    this.constants.PRICING,
    this.constants.DYN_PRICING,
    this.constants.DYNAMIC_PRICING,
    this.constants.DYN_PRICING_BATCH,
    this.constants.SALES_RFP,
    this.constants.PRICING_ANALYSIS,
    this.constants.RFP_REASON_CODES,
  ];
  private defaultSubCategories: string[] = [
    this.constants.STATE,
    _.startCase(_.toLower(this.constants.SIC)),
    this.constants.STATUS_CD,
    this.constants.QUALIFIER_TYPE,
    this.constants.RESTRICTION_TYPE,
    this.constants.APPLICATION_TYPE,
    this.constants.APPLICABLE_TYPE,
    this.constants.RATE_TYPE,
    this.constants.BULK_UPDATE_SIZE,
    this.constants.RATING_TARIFF,
    this.constants.LOCATION_TYPE,
    this.constants.PROGRAM_TYPE,
    this.constants.ELASTICSEARCH_ON,
    this.constants.SECURITY,
    this.constants.AC_CHARGE_TYPE,
    this.constants.JOHN_DEERE_LOCATION,
    this.constants.JOHN_DEERE_LANE_PAIR,
    this.constants.AC_UOM,
    this.constants.DP_RULE,
    this.constants.OPP,
    this.constants.EXPORT_RULES,
    this.constants.UPLOAD_RULES,
    this.constants.CUSTOMER_GROUPS,
    this.constants.TRIAL_PERIOD,
    this.constants.RULE_TYPE,
    this.constants.AUTO_ADJUST,
    this.constants.DPT,
    this.constants.SALES_REGION,
  ];

  private dptDiscountDefaultCodes: string[] = [
    DptDiscountCodes.cdi_default,
    DptDiscountCodes.cdi_minimum,
    DptDiscountCodes.cdi_maximum,
    DptDiscountCodes.fak_cdi_default,
    DptDiscountCodes.fak_cdi_minimum,
    DptDiscountCodes.fak_cdi_maximum,
    DptDiscountCodes.quarterly_cdi_default,
    DptDiscountCodes.quarterly_cdi_minimum,
    DptDiscountCodes.quarterly_cdi_maximum,
    DptDiscountCodes.quarterly_fak_cdi_default,
    DptDiscountCodes.quarterly_fak_cdi_minimum,
    DptDiscountCodes.quarterly_fak_cdi_maximum,
  ];

  constructor(private pricingWorkbenchApiService: PricingWorkbenchApiService, private constants: ConstantsService) {}

  listPricingCodes(params: CodesQuery = null) {
    const query = new ListPricingCodesQuery();
    query.category = params ? params.category : this.defaultCategories;
    query.subCategory = params ? params.subCategory : this.defaultSubCategories;
    return this.pricingWorkbenchApiService.listPricingCodes(query).pipe(
      map((result) => result.pricingCodes),
      catchError((error) => {
        return of(PricingWorkbenchCodes);
      })
    );
  }

  listDptDiscountPricingCodes(date) {
    const queryParams = new ListPricingCodesQuery();
    queryParams.category = [this.constants.SALES_RFP];
    queryParams.subCategory = [this.constants.DP_RULE];
    queryParams.code = this.dptDiscountDefaultCodes;
    queryParams.effectiveDate = date;
    return this.pricingWorkbenchApiService.listPricingCodes(queryParams).pipe(map((result) => result.pricingCodes));
  }

  getPricingCodes(): Observable<PricingCode[]> {
    if (!this.pricingCodesCache$) {
      this.pricingCodesCache$ = this.listPricingCodes().pipe(shareReplay());
    }
    return this.pricingCodesCache$;
  }

  updatePricingCodes(codes: PricingCode[]) {
    const requests = [];
    codes.forEach((code: PricingCode) => {
      const request = new UpdatePricingCodeRqst(),
        pathParams = new UpdatePricingCodePath();
      request.pricingCode = code;
      pathParams.pricingCodeId = code.pricingCodeId;
      requests.push(this.pricingWorkbenchApiService.updatePricingCode(request, pathParams));
    });
    return forkJoin(requests);
  }

  createPricingCodes(codes: Array<PricingCode>) {
    const requests = [];
    codes.forEach((code: PricingCode) => {
      const request = new CreatePricingCodeRqst();
      request.pricingCode = code;
      requests.push(this.pricingWorkbenchApiService.createPricingCode(request));
    });
    return forkJoin(requests);
  }

  requestDownloadFieldsCodes(): Observable<PricingCode[]> {
    const queryParams = new ListPricingCodesQuery();
    queryParams.category = [this.constants.DYN_PRICING];
    queryParams.subCategory = [this.constants.DOWNLOAD_HELP_TEXT];
    return this.pricingWorkbenchApiService.listPricingCodes(queryParams, { loadingOverlayEnabled: false }).pipe(
      map((response: ListPricingCodesResp) => {
        response.pricingCodes.sort((a, b) => Number(a.displayOrder) - Number(b.displayOrder));
        return response.pricingCodes;
      })
    );
  }

  listPricingAnalysts(employeeId: string): Observable<ListPricingAnalystsResp> {
    const query = new ListPricingAnalystsQuery();
    query.employeeId = employeeId;
    return this.pricingWorkbenchApiService.listPricingAnalysts(query);
  }

  listPricingAnalystsBasedOnEmpRole(): Observable<ListPricingAnalystsResp> {
    const request = new ListPricingAnalystsQuery();
    request.employeeRoleType = `${this.constants.ROLE_ACCOUNT_EXECUTIVE},${this.constants.ROLE_NATIONAL_ACCOUNT_EXECUTIVE}`;
    return this.pricingWorkbenchApiService.listPricingAnalysts(request);
  }

  getAnalyst(employeeId: string): Observable<AdminPersonnel> {
    return this.listPricingAnalysts(`${employeeId},${employeeId}`).pipe(
      map((response: any) => {
        return response.pricingAnalysts[0];
      })
    );
  }

  getUploadRulesFileSize() {
    return this.getPricingCodes().pipe(
      map((resp: PricingCode[]) => {
        return Number(
          _.find(resp, { subCategory: this.constants.UPLOAD_RULES, code: this.constants.PAGE_SIZE })?.value
        );
      })
    );
  }

  listSalesRfps(queryParams: ListSalesRfpHeadersQuery): Observable<SalesRfpHeader[]> {
    return this.pricingWorkbenchApiService.listSalesRfpHeaders(queryParams).pipe(
      map((resp: ListSalesRfpHeadersResp) => {
        return resp.salesRfpHeaders;
      })
    );
  }

  getSalesRfpBasedOnId(rfpId): Observable<GetSalesRfpResp> {
    const rfpPathparams = new GetSalesRfpPath(),
      rfpQueryParams = new GetSalesRfpQuery();
    rfpPathparams.salesRfpId = rfpId;
    return this.pricingWorkbenchApiService.getSalesRfp(rfpPathparams, rfpQueryParams, { loadingOverlayEnabled: true });
  }

  deleteRfpRuleset(salesRfpId: string): Observable<void> {
    const request = new DeleteSalesRfpAcctsRulesetPath();
    request.salesRfpId = salesRfpId;
    const headers = new HttpHeaders().set(this.constants.InterceptorSkipHeader, '');
    return this.pricingWorkbenchApiService.deleteSalesRfpAcctsRuleset(request, { toastOnError: false }, { headers });
  }

  /** CRUD Pricing Codes */
  createPricingCode(request: CreatePricingCodeRqst): Observable<CreatePricingCodeResp> {
    const headers = new HttpHeaders().set(this.constants.InterceptorSkipHeader, '');
    return this.pricingWorkbenchApiService.createPricingCode(request, {}, { headers });
  }

  updatePricingCode(
    pathParams: UpdatePricingCodePath,
    request: UpdatePricingCodeRqst
  ): Observable<UpdatePricingCodeResp> {
    const headers = new HttpHeaders().set(this.constants.InterceptorSkipHeader, '');
    return this.pricingWorkbenchApiService.updatePricingCode(request, pathParams, {}, { headers });
  }

  deletePricingCode(pricingCodeId: number): Observable<void> {
    const pathParams = new DeletePricingCodePath();
    pathParams.pricingCodeId = pricingCodeId;
    return this.pricingWorkbenchApiService.deletePricingCode(pathParams);
  }
  /** END CRUD Pricing Codes */
}
