export enum DptDiscountCodes {
  cdi_default = 'CdiDefault',
  cdi_maximum = 'CdiMaximum',
  cdi_minimum = 'CdiMinimum',
  fak_cdi_default = 'FakCdiDefault',
  fak_cdi_minimum = 'FakCdiMinimum',
  fak_cdi_maximum = 'FakCdiMaximum',
  quarterly_cdi_default = 'QuarterlyCdiDefault',
  quarterly_cdi_minimum = 'QuarterlyCdiMinimum',
  quarterly_cdi_maximum = 'QuarterlyCdiMaximum',
  quarterly_fak_cdi_default = 'QuarterlyFakCdiDefault',
  quarterly_fak_cdi_minimum = 'QuarterlyFakCdiMinimum',
  quarterly_fak_cdi_maximum = 'QuarterlyFakCdiMaximum',
}
